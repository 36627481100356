#prgdpr-ccpa-form {
  max-width: 37.1428571429rem;
  border: 0;
  background-color: #efefef;
  padding: 2rem;
  box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0;
}

.ccpa-form-wrapper .action-wrapper {
  margin-top: 2rem;
}
.ccpa-form-wrapper .field {
  margin-bottom: 1rem;
}